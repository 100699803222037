/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import ReactMarkdown from "react-markdown"
import unwrapImages from "remark-unwrap-images"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Text, Grid } from "theme-ui"
import { useSpring, animated, config } from "react-spring"
import { PopInLeft } from "react-spring-pop"
import { IconContext } from "react-icons"
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClockCircle,
} from "react-icons/ai"
import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"
momentDurationFormatSetup(moment)

const ProjectTemplate = ({ data, pageContext }) => {
  var rastgele = Math.random() * 10 - Math.random() * 10
  var rastgele2 = Math.random() * 10 - Math.random() * 10
  const datestart = moment(data.strapiProject.date)
  // const dateyear = moment(data.strapiProject.date).format('YYYY');
  const dateend = moment(data.strapiProject.enddate).add(1, "days")
  const dateduration = dateend.diff(datestart, "days")
  const datedur = moment
    .duration(dateduration, "days")
    .format("y [years], M [months], w [weeks], d [days]", {
      largest: 1,
    })
  const delay = 125
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: "translate3d(0,-25px,0)" },
    to: async next => {
      await next({ opacity: 1, transform: "translate3d(0,0,0)" })
    },
  })
  const fade2 = useSpring({
    config: config.stiff,
    from: {
      opacity: 0,
      // transform: `perspective(600px) skew(-20deg, -20deg) translate3d(-50px,-50px,0) rotateZ(${rastgele}deg)`,
    },
    to: async next => {
      await next({
        opacity: 1,
        // transform: `perspective(600px) skew(0deg, 0deg)   translate3d(0,0,0) rotateZ(${rastgele2}deg)`,
        delay: delay * 2,
      })
    },
  })
  const fade3 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: "translate3d(0,100px,0)" },
    to: async next => {
      await next({
        opacity: 1,
        transform: "translate3d(0,0,0)",
        delay: delay * 2,
      })
    },
  })

  const AnimatedImg = animated(Img)
  const AnimatedBox = animated(Box)
  const AnimatedText = animated(Text)

  return (
    <Layout>
      <SEO title={data.strapiProject.title} />

      <Box>
        <Box
          sx={{
            mx: "auto",
            // backgroundColor: 'primary',
            // textAlign: "center",
          }}
        >
          <AnimatedBox
            style={fade}
            sx={{
              maxWidth: "60em",
              color: "primary",
              mx: "auto",
              py: "6",
            }}
          >
            <animated.h1
              sx={{
                fontSize: 8,
                fontFamily: "heading",
                fontStyle: "italic",
                fontWeight: "900",
                // borderBottom: "solid 1px black",
              }}
            >
              {data.strapiProject.title}
            </animated.h1>
            <Text
              sx={{
                fontSize: "5",
                fontWeight: "250",
                lineHeight: "list",
                my: "5",
              }}
            >
              {data.strapiProject.summary}
            </Text>
            <Text
              sx={{
                textTransform: "uppercase",
                py: "2",
                overflow: "hidden",
                fontWeight: "body",
                fontSize: "3",
              }}
            >
              {data.strapiProject.category}
              {data.strapiProject.enddate && (
                <>
                  <AiOutlineClockCircle
                    sx={{
                      size: "1.35em",
                      display: "inline-block",
                      verticalAlign: "top",
                      py: "1",
                      ml: ["2", "3"],
                    }}
                  />
                  <AnimatedText sx={{ ml: "0", display: "inline" }}>
                    {datedur}
                  </AnimatedText>
                </>
              )}
            </Text>
          </AnimatedBox>
        </Box>
      </Box>
      <AnimatedBox style={fade3} className="projectMDX">
        <Grid
          className="grid-container"
          gap={[1, 2, 3]}
          columns={["repeat(1,1fr)"]}
          sx={{ mx: "auto", display: ["block", "grid"], maxWidth: "60em" }}
        >
          <ReactMarkdown
            source={data.strapiProject.description}
            plugins={[unwrapImages]}
            linkTarget={"_blank"}
            parserOptions={{ commonmark: true }}
            renderers={{
              image: ({ src, alt }) => {
                const image = data.strapiProject.description_images.find(
                  element => element.base === src
                )
                return (
                  <div className={"projectImage"}>
                    <Img fluid={image.childImageSharp.fluid} alt={alt} />
                  </div>
                )
              },
              code: ({ language, value }) => {
                // if (language === 'emoji') {
                //   return <Emoji text={value} />
                // }

                const className = language && `language-${language}`
                const code = React.createElement(
                  "code",
                  className ? { className: className } : null,
                  value
                )
                return React.createElement("pre", {}, code)
              },
              blockquote: ({ children }) => (
                <blockquote>
                  <span
                    sx={{
                      display: "inline",
                      color: "background",
                      fontSize: "12.5em",
                      position: "absolute",
                      lineHeight: "1",
                      mt: ".05em",
                      ml: "0",
                      opacity: 0.5,
                    }}
                  >
                    &#8220;
                  </span>
                  {children}
                </blockquote>
              ),

              // heading: ({ children, level }) => {
              //   return (
              //     <PopInLeft
              //       once={true}
              //       tension={120}
              //       friction={14}
              //       threshold={0}
              //       className={"projectHeader"}
              //       sx={{ mt: "3", pb: "2", zIndex: "1" }}
              //     >
              //       <Text
              //         as={`h${level}`}
              //         sx={{
              //           fontFamily: "heading",
              //           // transform: `rotateZ(${Math.floor(
              //           //   Math.random() * 5 - Math.random() * 5
              //           // )}deg)`,
              //         }}
              //       >
              //         <span
              //           css={"-webkit-backface-visibility: hidden;"}
              //           sx={{}}
              //         >
              //           {children}
              //         </span>
              //       </Text>
              //     </PopInLeft>
              //   )
              // },
            }}
          />
        </Grid>
      </AnimatedBox>

      {/* PREVIOUS/NEXT NAVIGATION */}
      <Grid
        px={["0", "2"]}
        pb={"4"}
        pt={"0"}
        sx={{
          lineHeight: "1.25",
          width: "100%",
          position: "fixed",
          left: "0",
          bottom: "0",
        }}
        columns={["max-content auto max-content"]}
      >
        <IconContext.Provider value={{ size: "4em" }}>
          {pageContext.prevtitle && (
            <Box
              sx={{
                float: "left",
                position: "relative",
                gridColumnStart: "1",
                fontSize: ["0", "1"],
              }}
            >
              <Link
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gridTemplateRows: "auto",
                  color: "gray",
                  py: "3",
                  pr: "3",
                  borderRadius: "10px",
                  backgroundColor: "rgba(255,255,255,0)",
                  opacity: "0.5",
                  ":hover": {
                    color: "primary",
                    transition: "opacity color background-color 0.3s ease-in",
                    backgroundColor: "rgba(255,255,255,0.75)",
                    opacity: "1",
                  },
                  ":hover>div": {
                    transition: "opacity 0.3s ease-in",
                    opacity: "1",
                  },
                }}
                to={`/projects/${pageContext.prev}`}
              >
                <AiOutlineArrowLeft
                  sx={{
                    display: "block",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                  }}
                />
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "1",
                    fontWeight: "body",
                  }}
                >
                  Previous project:
                </Box>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    pt: "16px",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "2",
                    fontWeight: "body",
                  }}
                >
                  {pageContext.prevtitle}
                </Box>
              </Link>
            </Box>
          )}
          {pageContext.nexttitle && (
            <Box
              sx={{
                float: "right",
                position: "relative",
                gridColumnStart: "3",
                textAlign: "right",
                fontSize: ["0", "1"],
              }}
            >
              <Link
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gridTemplateRows: "auto",
                  color: "gray",
                  py: "3",
                  pl: "3",
                  borderRadius: "10px",
                  opacity: "0.5",
                  ":hover": {
                    color: "primary",
                    transition: "opacity color background-color 0.3s ease-in",
                    backgroundColor: "rgba(255,255,255,0.75)",
                    opacity: "1",
                  },
                  ":hover>div": {
                    transition: "opacity 0.3s ease-in",
                    opacity: "1",
                  },
                }}
                to={`/projects/${pageContext.next}`}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "1",
                    fontWeight: "body",
                  }}
                >
                  Next project:
                </Box>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    pt: "16px",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "2",
                    fontWeight: "body",
                  }}
                >
                  {pageContext.nexttitle}
                </Box>
                <AiOutlineArrowRight
                  sx={{
                    display: "block",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                  }}
                />
              </Link>
            </Box>
          )}
        </IconContext.Provider>
      </Grid>
      {/* PREVIOUS/NEXT NAVIGATION */}
    </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectTemplate($id: String!) {
    strapiProject(id: { eq: $id }) {
      id
      slug
      title
      category
      description
      description_images {
        childImageSharp {
          original {
            src
          }
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        base
      }
      date
      enddate
      summary

      cover {
        childImageSharp {
          fluid(
            maxWidth: 1200
            maxHeight: 600
            cropFocus: CENTER
            quality: 100
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

// childStrapiProjectContent {
//   childMdx {
//     body
//     id
//   }
// }
